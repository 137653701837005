.about-us-banner {
  position: relative;
  z-index: -1;
}
.about-us-banner img {
}
.about-us-banner h1 {
  font-size: 64px;
  position: absolute;
  font-family: Fugi;
  color: white;
  text-align: center;
  top: 65%;
  left: 40%;
  transform: translate(-50%, -50%);
}
.about-us-compass-img {
  display: block;
  width: 130px;
  margin: auto;
  padding-bottom: 40px;
}
.about-us-content-container {
  display: block;
  max-width: 1200px;
  margin: auto;
  /* top right bottom left */
  padding: 50px 40px 50px 40px;
}
.about-us-content-container p {
  text-align: center;
  font-size: 24px;
  line-height: 35.64px;
}
.image-list {
  display: flex;
  padding-top: 60px;
  justify-content: space-between;
}
.image-list-item {
  width: 32%;
}
.image-list-item img {
  width: 100%;
}
.employee-container {
  max-width: 1200px;
  margin: auto;
  padding: 0px 40px 150px 40px;
}
.employee-container h1 {
  font-family: Fugi;
  text-align: center;
  font-size: 64px;
}
.employee-list {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.employee-item {
  width: 26%;
}
.employee-item img {
  width: 100%;
  margin: 20px 0px;
}
.employee-item p {
  font-size: 18px;
  margin-bottom: 2px;
}
.pink-line {
  margin-top: 10px;
  margin-bottom: 2px;
  width: 50px;
  border-bottom: 4px solid pink;
}
@media screen and (max-width: 767px) {
  .about-us-compass-img {
    width: 100px;
  }
  .about-us-content-container {
    padding: 25px 20px 75px 20px;
  }
  .about-us-content-container p {
    font-size: 16px;
    line-height: 25px;
  }
  .about-us-banner img {
    min-height: 200px;
    object-fit: cover;
    object-position: center;
  }
  .about-us-banner h1 {
    font-size: 40px;
    top: 65%;
    left: 35%;
    transform: translate(-50%, -50%);
  }
  .image-list {
    display: grid;
    padding-top: 30px;
    max-width: 550px;
    margin: auto;
  }
  .image-list-item {
    width: 100%;
  }
  .grid-item1 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .grid-item2 {
    grid-column-start: 3;
  }
  .employee-item {
    width: 40%;
  }
  .employee-container h1 {
    font-size: 6vw;
  }
  .employee-item p {
    font-size: 2.4vw;
  }
  .pink-line {
    width: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .about-us-compass-img {
    width: 110px;
  }
  .about-us-content-container {
    padding: 40px 30px 90px 30px;
  }
  .about-us-content-container p {
    font-size: 16px;
    line-height: 25px;
  }
  .about-us-banner img {
    min-height: 250px;
    object-fit: cover;
    object-position: center;
  }
  .about-us-banner h1 {
    top: 70%;
    left: 35%;
  }
  .employee-item {
    width: 40%;
  }
  .employee-container h1 {
    font-size: 6vw;
  }
  .employee-item p {
    font-size: 2.4vw;
  }
  .pink-line {
    width: 8vw;
  }
}
