.article-detail-container {
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
.article-detail-cover-img {
  display: block;
  max-width: 100%;
  margin: auto;
}
.content-zone {
  display: flex;
}
.article-content {
  display: block;
  padding: 40px;
  width: 100%;
}
.article-detail img {
  display: block;
  max-width: 80%;
  height: auto;
  margin: auto;
}
.share-content {
  display: block;
  width: 100px;
  padding-right: 40px;
}

.sticky-share {
  position: sticky;
  position: -webkit-sticky;
  top: 30%;
  margin-top: 30%;
}
.share-list {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 60px;
}
.share-list button {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.related-article-list {
  padding: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .content-zone {
    display: block;
  }
  .article-content {
    display: block;
    padding: 20px;
    width: 100%;
  }
  .article-detail {
    font-size: 12px;
  }
  .article-detail img {
    max-width: 80%;
  }
  .share-content {
    width: 100%;
  }

  .sticky-share {
    margin: 0px 20px;
    position: block;
  }
  .share-list {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .related-article-list {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .article-detail p {
    font-size: 12px;
  }
  .article-detail li {
    font-size: 12px;
  }
  .article-detail span {
    font-size: 12px;
  }
  .article-detail a {
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
