.our-service-bg {
  color: #ffffff;
  background: linear-gradient(180deg, #20466b 0%, #041e42 72.22%);
}
.title {
  font-family: Fugi;
  text-align: center;
  padding-top: 76px;
  padding-bottom: 76px;
  font-size: 64px;
}
.ourservice-item {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  text-align: center;
}
.collapseImageBig {
  width: 40vw;
  max-width: 600px;
  border-radius: 0px;
  margin-bottom: 75px;
}

.collapseImageA {
  grid-column-start: 1;
  position: relative;
  margin-right: 50px;
}
.collapseImageB {
  grid-column-start: 2;
  position: relative;
  margin-left: 50px;
}
.ourservice-item-contentA {
  grid-column-start: 2;
}
.ourservice-item-contentB {
  grid-column-start: 1;
}
.ourservice-item-title-container {
  position: relative;
  font-size: 50px;
  font-weight: unset;
  line-height: 32px;
  letter-spacing: 0em;
}
.ourservice-item-title-container img {
  width: 100%;
  max-width: 400px;
}
.ourservice-item-desc {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0em;
  margin: 50px 0;
}

.learnMore {
  height: 54px;
  width: 182px;
  border-radius: 56px;
  font-size: 24px;
  background: #ffffff;
  margin: auto;
  border: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  .title {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 45px;
  }
  .ourservice-item {
    position: relative;
    margin: 50px 0;
  }
  .collapseImageBig {
    width: 280px;
    margin: 30px 0;
    grid-column-start: 1;
  }
  .ourservice-item-title-container {
    font-size: 40px;
    font-weight: unset;
    line-height: 28px;
    letter-spacing: 0em;
  }
  .ourservice-item-title-container img {
    width: 200px;
  }
  .title-1 {
    position: absolute;
    top: 0;
    left: 40%;
  }
  .title-2 {
    position: absolute;
    top: -4%;
    left: 0;
    z-index: 10;
  }
  .title-3 {
    position: absolute;
    top: -5.5%;
    left: 40%;
    z-index: 10;
  }
  .title-4 {
    position: absolute;
    top: 50%;
    left: -5%;
    z-index: 10;
  }
  .ourservice-item-desc {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 25px 0;
  }
  .learnMore {
    height: 40px;
    width: 150px;
    border-radius: 40px;
    font-size: 20px;
    background: #ffffff;
    margin: auto;
  }
  .collapseImageA {
    position: relative;
    grid-column-start: 1;
    grid-row-start: 1;
    margin-right: 0;
  }
  .collapseImageB {
    position: relative;
    grid-column-start: 1;
    grid-row-start: 1;
    margin-left: 0;
  }
  .ourservice-item-contentA {
    grid-column-start: 1;
    grid-row-start: 2;
  }
  .ourservice-item-contentB {
    grid-column-start: 1;
    grid-row-start: 2;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .title {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 45px;
  }
  .ourservice-item {
    margin: 50px 0;
  }
  .collapseImageBig {
    width: 360px;
    margin: 30px 0;
    grid-column-start: 1;
  }
  .ourservice-item-desc {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 25px 0;
  }
  .learnMore {
    height: 40px;
    width: 150px;
    border-radius: 40px;
    font-size: 20px;
    background: #ffffff;
    margin: auto;
  }
  .collapseImageA {
    grid-column-start: 1;
    position: relative;
    margin-right: 50px;
  }
  .collapseImageB {
    grid-column-start: 2;
    position: relative;
    margin-left: 50px;
  }
  .ourservice-item-title-container img {
    width: 100%;
    max-width: 300px;
  }
}
