.header-bg {
  height: var(--header-h);
  background-color: #041e42;
}
.content-container {
  min-height: 1500px;
  background: linear-gradient(180deg, #1d4267 0%, #041e42 100%);
  color: white;
  padding-top: 150px;
  overflow: hidden;
}
.category-list {
  text-align: center;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  font-size: 23px;
}
.category-item {
  padding: 0px 25px;
  border-right: 1px solid #fff;
  align-self: center;
  cursor: pointer;
}
.category-item:last-child {
  padding: 0px 25px;
  border-right: 1px solid transparent;
}
.gallery {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
.gallery h1 {
  font-family: Fugi;
  font-size: 128px;
  text-align: center;
  /* 1.4568627451 */
}
.gallery-img-slide {
  position: relative;
  margin-top: 120px;
}
.gallery-img-current {
  position: absolute;
  background: #ffffff;
  box-shadow: 20px 19px 13px rgba(0, 0, 0, 0.2);
  transform: translate(-50%) rotate(3.53deg);
  z-index: 10;
  left: 50%;
  /* transform: ; */
}
.gallery-img-current img {
  /* position: absolute; */
  /* padding-top: 5%; */
  width: 650px;
  /* height: 445px;  */
  padding: 2% 2% 10% 2%;
  object-fit: cover;
}
.gallery-img-next {
  position: absolute;
  width: 450px;
  height: 310px;
  left: 85%;
  top: 100px;

  background: #ffffff;
  box-shadow: 20px 19px 13px rgba(0, 0, 0, 0.2);
  transform: translate(-50%) rotate(-1.76deg);
  z-index: 5;
}
.gallery-img-next img {
  width: 450px;
  height: 310px;
  padding: 2% 2% 10% 2%;
  object-fit: cover;
}

.gallery-img-prev {
  position: absolute;
  width: 450px;
  height: 310px;
  left: -25%;
  top: 100px;

  background: #ffffff;
  box-shadow: 20px 19px 13px rgba(0, 0, 0, 0.2);
  transform: translate(50%) rotate(-1.76deg);
  z-index: 5;
  object-fit: cover;
  object-position: 100% 0;
  object-position: 0 100%;
}
.gallery-img-prev img {
  width: 450px;
  height: 310px;
  padding: 2% 2% 10% 2%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .content-container {
    min-height: 850px;
  }
  .category-list {
    padding-top: 50px;
    font-size: 8px;
  }
  .category-item {
    padding: 0px 12px;
  }
  .gallery {
    max-width: 600px;
  }
  .gallery h1 {
    font-size: 50px;
  }
  .gallery-img-slide {
    margin-top: 60px;
  }
  .gallery-img-current img {
    width: 300px;
  }
  .gallery-img-next {
    width: 225px;
    height: 155px;
    top: 50px;
  }
  .gallery-img-next img {
    width: 225px;
    height: 155px;
  }

  .gallery-img-prev {
    width: 225px;
    height: 155px;
    top: 50px;
    left: -30%;
  }
  .gallery-img-prev img {
    width: 225px;
    height: 155px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .content-container {
    min-height: 1200px;
  }
  .category-list {
    padding-top: 50px;
    font-size: 12px;
  }
  .category-item {
    padding: 0px 16px;
  }
  .gallery {
    max-width: 800px;
  }
  .gallery-img-slide {
    margin-top: 80px;
  }
  .gallery-img-current img {
    width: 433px;
  }
  .gallery-img-next {
    width: 300px;
    height: 206px;
    top: 66px;
  }
  .gallery-img-next img {
    width: 300px;
    height: 206px;
  }

  .gallery-img-prev {
    width: 300px;
    height: 206px;
    top: 66px;
  }
  .gallery-img-prev img {
    width: 300px;
    height: 206px;
  }
}
