.highlight-article {
  position: relative;
}
.highlight-article img {
  /* position: absolute; */
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto;
  min-height: 300px;
  max-height: 450px;
}
.highlight-article-detail {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 45%;
  transform: translate(0%, -50%);
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.7);
}
.highlight-article a {
  text-decoration: none;
}
.read-article-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  /* height: 62px; */
  /* width: 214px; */
  border-radius: 12px;
  margin-top: 30px;
  font-size: 22px;
  border: 1px solid transparent;
  background-color: #bc8a7e;
  color: white;
}
.highlight-date {
  color: #fff;
  font-size: 20px;
}
.tag-list {
  display: flex;
  margin: 20px 0px;
}
.tag-list span {
  margin-right: 10px;
}
.tag-item {
  display: block;
  height: 30px;
  min-width: 77px;
  border-radius: 3px;
  padding: 0px 10px;
  font-size: 12px;
  border: 1px solid transparent;
  background-color: rgba(229, 229, 229);
  color: #a0a0a0;
  margin-right: 10px;
  text-decoration: none;
  line-height: 2.5;
  text-align: center;
}
.tag-item:hover {
  color: #a0a0a0;
}
.highlight-title {
  font-size: 36px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #fff;
}

.highlight-intro {
  font-size: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: #fff;
}
.article-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
.filter-container {
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
}
.popular-tag p {
  font-size: 12px;
  line-height: 15px;
  color: #a0a0a0;
}
.sort-by {
  display: flex;
  margin: 20px 0px;
  align-items: baseline;
  font-size: 20px;
}
.sort-by select {
  width: 200px;
  height: 30px;
  margin-left: 20px;
  border-radius: 40px;
  background-color: transparent;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  text-align: center;
}
.article-list {
  display: flex;
  flex-wrap: wrap;
}
.article-item {
  margin-top: 50px;
  width: 30%;
  margin-left: 1.667%;
  margin-right: 1.667%;
}
.article-item img {
  max-width: 100%;
  height: 16vw;
  /* min-width: 275px; */
  min-height: 170px;
  object-fit: cover;
  object-position: center;
  border-radius: 21px;
  display: block;
  margin: auto;
}
.article-date {
  margin-top: 25px;
  font-size: 20px;
  line-height: 25px;
  color: #c4c4c4;
}

.article-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 42px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: #000;
  text-decoration: none;
}
.article-title:hover {
  color: #000;
}
@media screen and (max-width: 767px) {
  .highlight-article-detail {
    width: 60%;
  }
  .read-article-btn {
    font-size: 11px;
    padding: 8px 16px;
    border-radius: 6px;
  }
  .highlight-date {
    font-size: 10px;
  }
  .tag-item {
    font-size: 6px;
    min-width: 35px;
    height: 15px;
    padding: 0px 5px;
  }
  .highlight-title {
    font-size: 16px;
  }
  .highlight-intro {
    font-size: 11px;
  }
  .popular-tag p {
    font-size: 6px;
  }
  .article-date {
    font-size: 10px;
  }
  .article-title {
    font-size: 13px;
    line-height: 16px;
  }
  .popular-tag .tag-list {
    margin: 0px;
  }
  .popular-tag p {
    margin: 0px;
  }
  .sort-by {
    display: block;
    font-size: 6px;
    margin: 0px;
    text-align: right;
    /* padding-left: 10px; */
  }
  .sort-by select {
    margin-top: 5px;
    width: 100px;
    height: 15px;
    border-radius: 20px;
  }

  .article-item {
    display: flex;
    margin-top: 20px;
    width: 100%;
  }
  .article-item-detail {
    margin-left: 20px;
    width: 50%;
  }
  .article-item img {
    width: 250px;
    /* max-wid  th: 60%; */
    /* min-width: 200px; */
    min-height: 170px;
    object-fit: cover;
    object-position: center;
    border-radius: 21px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .highlight-article-detail {
    width: 50%;
  }
  .read-article-btn {
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 6px;
  }
  .highlight-date {
    font-size: 15px;
  }
  .tag-item {
    font-size: 9px;
    min-width: 35px;
    height: 20px;
    padding: 0px 7px;
    line-height: 2;
  }
  .highlight-title {
    font-size: 20px;
  }
  .highlight-intro {
    font-size: 16px;
  }
  .popular-tag p {
    font-size: 10px;
  }
  .article-date {
    font-size: 15px;
  }
  .article-title {
    font-size: 18px;
    line-height: 28px;
  }
  .sort-by {
    font-size: 15px;
  }
}
@media screen and (min-width: 1024px) {
}
