:root {
  --header-h: 150px;
}
@font-face {
  font-family: "Fugi";
  src: url("../src/assets/fonts/fonts-en/Fugi.ttf");
}
@font-face {
  font-family: "Pragmatiga";
  src: url("../src/assets/fonts/fonts-en/PragmaticaExtended-Book.ttf");
}
@font-face {
  font-family: "Quentin";
  src: url("../src/assets/fonts/fonts-en/Quentin.ttf");
}
@font-face {
  font-family: "FCSubject";
  src: url("../src/assets/fonts/fonts-th/FCSubjectRegular.ttf");
}
@font-face {
  font-family: "Sarabun";
  src: url("../src/assets/fonts/fonts-th/Sarabun-Regular.ttf");
}
.app {
  font-family: "Pragmatiga", "FCSubject";
  object-fit: cover;
}

.app-background-container {
  overflow: hidden;
}
.app-content-container {
  display: block;
  max-width: 1200px;
  margin: auto;
  padding: var(--header-h) 40px;
}

.nav {
  position: absolute !important;
  width: 100%;
  height: var(--header-h);
}
.nav-collapse {
  width: 100vw;
  height: var(--header-h);
}
.nav-brand {
  position: absolute;
}
.nav-brand img {
  width: 300px;
}
.nav-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 40px;
}
.nav-item-list {
  display: flex;
}
.nav-item {
  padding: 0.5rem 1rem;
}
.nav-item a {
  display: block;
  color: #fff !important;
  text-decoration: none;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 5px;
  border-bottom: 2px transparent solid;
  width: fit-content;
  /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out; */
}
.nav-item a:hover {
  border-bottom: 2px #bc8a7e solid;
}
.active-route {
  border-bottom: 2px #bc8a7e solid !important;
}
.nav-toggler {
  cursor: pointer;
  color: #fff;
}
.nav-toggler svg {
  display: none;
  width: 40px;
  height: 40px;
}
.nav-search {
  color: #fff;
}
/* class search-input slide appear from right with round border */
.search-input {
  position: absolute;
  top: var(--header-h);
  right: 0;
  margin-right: 20px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid #777;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  transform: translateX(120%);
  z-index: 100;
}

.footer {
  padding: 20px 60px;
}
.contact-bar {
  display: flex;
  padding: 30px 0px;
  border-top: 1px solid #c4c4c4;
  /* border-bottom: 1px solid #c4c4c4; */
}
.contact-bar p {
  line-height: 17.44px;
}
.social-bar {
  display: flex;
  padding: 10px 0px;
}
.social-bar img {
  margin: 5px 10px;
  width: 25px;
  height: auto;
}
/* .social-bar a {
  margin: 5px 2px;
  width: 25px;
  height: auto;
} */
.footer-item {
  width: 25%;
}

.paginator {
  display: flex;
  justify-content: center;
  margin: 150px 0px 100px 0px;
  align-items: center;
}
.paginator input {
  width: 110px;
  height: 62px;
  border: 2px solid #c4c4c4;
  border-radius: 10px;
  text-align: center;
  font-size: 32px;
}
.paginator button {
  font-size: 32px;
  background-color: transparent;
  border: unset;
  margin: 0px 30px;
  color: #c4c4c4;
}
.paginator span {
  font-weight: 300;
  font-size: 32px;
  line-height: 39px;
  color: #c4c4c4;
}
.side-nav {
  /* display: none; */
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: linear-gradient(180deg, #20466b 0%, #041e42 72.22%);
  z-index: 100;
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
}
.active {
  /* display: block; */
  transform: translateX(0%);
}
.side-nav-brand img {
  width: 100%;
}
.side-nav-item-list {
  padding: 0px 20px;
}
.close-side-nav {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 20px;
  cursor: pointer;
  transform: translateY(-50%);
}
.close-side-nav svg {
  width: 40px;
  height: 40px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  :root {
    --header-h: 90px;
  }
  .nav-list {
    padding: 0 20px;
    height: 75px;
  }
  .nav-toggler svg {
    display: block;
    width: 30px;
    height: 30px;
  }
  .nav-item-list {
    display: none;
  }
  .nav-brand img {
    width: 150px;
  }
  .nav-brand {
    left: 50%;
    transform: translateX(-50%);
  }
  .paginator {
    margin: 75px 0px 50px 0px;
  }
  .paginator input {
    width: 55px;
    height: 31px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    font-size: 16px;
  }
  .paginator button {
    font-size: 16px;
    margin: 0px 15px;
  }
  .paginator span {
    font-weight: 150;
    font-size: 16px;
    line-height: 19px;
  }
  .footer {
    padding: 20px 60px;
  }
  .contact-bar {
    display: block;
    margin: auto;
    padding: 30px 0px;
    border-top: 1px solid #c4c4c4;
    /* justify-content: center; */
    text-align: center;
    /* border-bottom: 1px solid #c4c4c4; */
  }
  .contact-bar p {
    line-height: 17.44px;
  }
  .social-bar {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
  }
  .footer-item {
    display: block;
    width: auto;
    margin: auto;
    margin-top: 30px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .nav-toggler svg {
    display: block;
  }
  .nav-item-list {
    display: none;
  }
  .nav-brand img {
    width: 250px;
  }
  .nav-brand {
    left: 50%;
    transform: translateX(-50%);
  }
  .paginator {
    margin: 100px 0px 75px 0px;
  }
  .paginator input {
    width: 70px;
    height: 37px;
    border: 1px solid #c4c4c4;
    border-radius: 7px;
    font-size: 20px;
  }
  .paginator button {
    font-size: 20px;
    margin: 0px 20px;
  }
  .paginator span {
    font-weight: 150;
    font-size: 20px;
    line-height: 21px;
  }
  .footer {
    padding: 20px 60px;
  }
  .contact-bar {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0px;
    border-top: 1px solid #c4c4c4;
    /* border-bottom: 1px solid #c4c4c4; */
  }
  .contact-bar p {
    line-height: 17.44px;
  }
  .social-bar {
    display: flex;
    padding: 10px 0px;
  }
  .footer-item {
    width: 50%;
    margin-top: 40px !important;
  }
}
